<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <DxDataGrid
            v-if="can_list"
            id="material-table"
            ref="data-grid"
            width="100%"
            :show-borders="true"
            :data-source="databoq"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :column-auto-width="true"
            :column-auto-height="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :hover-state-enabled="true"
            :word-wrap-enabled="true"
            key-expr="id"
            @exporting="onExporting"
            @onexported="onExported"

        >

          <DxExport :enabled="true"/>
          <DxColumn
              type="buttons"
              :allow-exporting="false"
              :caption="$t('table.actions')"
          >

            <DxButton
                hint="Photo"
                icon="photo"
                :visible="photoIconVisible"
                :disabled="false"
                :on-click="photoIconClick"
            />
          </DxColumn>

          <DxColumn data-field="netQty" :name="$t('material.netQty')"
                    :caption="$t('material.netQty')" :allow-editing="false" :visible="false">
            <DxFormItem :visible="false"/>
          </DxColumn>
            <DxColumn data-field="id" caption="ID" :allow-editing="false">
            <DxFormItem :visible="false"/>
          </DxColumn>
          <DxColumn data-field="photo" cell-template="grid-cell-template" :visible="false"></DxColumn>
          <template #grid-cell-template="{ data }">
            <div>
              <img :src="data.value" height="100" width="100">
            </div>
          </template>
          <DxColumn
              data-field="material.material_group.languages.0.material_group_translation.name"
              :name="$t('material.type')"
              :caption="$t('material.type')"
          >

          </DxColumn>
          <DxColumn
              data-field="created_at"
              data-type="date"
              :name="$t('problem.created_at')"
              :caption="$t('problem.created_at')"
              format="dd.MM.yyyy"
              class="bold-detail"
              :allow-editing="true"
          />
          <DxColumn
              data-field="work.firm.name"
              :name="$t('problem.work_firm_name')"
              :caption="$t('problem.work_firm_name')"
          />
          <DxColumn
              data-field="chiefName"
              :visible="true"
              :name="$t('table.responsible')"
              :caption="$t('table.responsible')"
          >

          </DxColumn>
          <DxColumn
              data-field="work.submitter_id"
              :visible="true"
              :name="$t('problem.submitter')"
              :caption="$t('problem.submitter')"
          >
            <DxLookup
                :data-source="dataUsersName"
                :allowClearing="true"
                value-expr="id"
                display-expr="username"
            />
          </DxColumn>

          <DxColumn
              data-field="work.activity.languages.0.activity_translation.name"
              :name="this.$t('activity.activityName')"
              :caption="this.$t('activity.activityName')"
          />
          <DxColumn
              data-field="material.languages.0.material_translation.name"
              :caption="$t('table.material')"
          >
          </DxColumn>


          <DxColumn
              data-field="work.activity.code"
              :visible="false"
              :name="$t('problem.activity_code')"
              :caption="$t('problem.activity_code')"
          />
          <DxColumn
              data-field="work.quantity"
              :name="$t('table.Prd_Qty')"
              :caption="$t('table.Prd_Qty')"

          />

          <DxColumn
              data-field="work.activity.wb.code"
              :caption="this.$t('wbs.wbsCode')"
              :name="this.$t('wbs.wbsCode')"
              :visible="false"
          />

            <DxColumn
                    data-field="work.activity.unit_id"
                    :name="'Act UM'"
                    :caption="'Act UM'"
                    :allow-editing="false"
            >
                <DxLookup
                        :data-source="dataUnit"
                        unit
                        value-expr="id"
                        display-expr="symbol"
                />
            </DxColumn>
          <DxColumn
              data-field="quantity"
              :caption="$t('table.quantity')"
              format="#.000"
          />

            <DxColumn
                    data-field="material.unit_id"
                    :name="$t('table.symbol')"
                    :caption="$t('table.symbol')"
                    :allow-editing="true"
            >
                <DxLookup
                        :data-source="dataUnit"
                        unit
                        value-expr="id"
                        display-expr="symbol"
                />
            </DxColumn>
          <DxColumn
              data-field="description"
              :caption="$t('table.description')"
          >
            <DxLookup
                :data-source="
                    (levels = [
                      {
                        id: 'Used',
                        name: 'Used'
                      },
                      {
                        id: 'Borrow',
                        name: 'Borrow'
                      },
                      {
                        id: 'In',
                        name: 'In'
                      },
                      {
                        id: 'Stolen',
                        name: 'Stolen'
                      },
                      {
                        id: 'Expired',
                        name: 'Expired'
                      },
                      {
                        id: 'Unsuitable',
                        name: 'Unsuitable'
                      }
                    ])
                  "
                value-expr="id"
                display-expr="name"
            />
          </DxColumn>
          <DxColumn data-field="notes" :caption="$t('table.notes')"/>

          <DxFilterRow :visible="true"/>
          <DxSearchPanel :visible="true"/>
          <DxColumnFixing :enabled="true"/>
          <DxColumnChooser :enabled="true"/>
          <DxScrolling mode="standard"/>
          <DxGrouping :context-menu-enabled="true"/>
          <DxGroupPanel :visible="true"/>
          <DxPaging :page-size="20"/>

          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :show-page-size-selector="showPageSizeSelector"
              :show-info="showInfo"
              :show-navigation-buttons="showNavButtons"
          />


          <DxSummary>
            <DxGroupItem
                column="id"
                summary-type="count"
            />
          </DxSummary>
        </DxDataGrid>
        <h1 v-else>{{ $t('noPermission') }}</h1>
        <div v-if="photoModel != null">
          <DxPopup
              :visible="photoPop"
              :close-on-outside-click="false"
              :show-close-button="false"
              title="Photos"
          >
            <DxToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                :options="photoPopButton"
            />

            <div style="width: 100%" class="row">
              <div class="col-md-4">
                <img :src="photoModel.photo">
              </div>


            </div>
          </DxPopup>
        </div>
      </div>
    </div>

    <b-modal
        ref="import-modal"
        centered
        title="Import"
        ok-only
        :ok-title="$t('buttons.ok')"
    >
      <b-card-text>{{ $t('general.importStarted') }}</b-card-text>
      <b-card-text v-if="importResult.length !== 0">
        {{ importResult }}
      </b-card-text>
      <div class="d-flex justify-content-center">
        <b-spinner v-if="importResult.length === 0"/>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import saveAs from 'file-saver';
import axios from '@axios';
import {
  BCard,
  BModal,
  BCardText,
  BSpinner
} from 'bootstrap-vue';

import {
  DxDataGrid,
  DxLookup,
  DxColumn,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxFormItem,
  DxPager,
  DxGroupItem,
  DxSummary,
  DxButton,
} from 'devextreme-vue/data-grid';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import 'jspdf-autotable';
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";

let activityListF = [];
let materialListF = [];


export default {
  components: {
    BCard,
    BCardText,
    BModal,
    BSpinner,
    DxGroupItem,
    DxSummary,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxLookup,
    DxScrolling,
    DxSearchPanel,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxExport,
    DxFormItem,
    DxPager,
    DxButton,
    DxPopup, DxToolbarItem
  },
  data() {
    return {
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      dataProblemGroup: [],
      dataProblemType: [],
      dataPersonnelType: [],
      dataProblemTypeFiltered: [],
      dataUnit: [],
      dataUsers: [],
      dataUsersName: [],
      dataPositions: [],
      dataCurrency: [],
      databoq: [],
      dataProblem: [],
      languages: [],
      dataEditMaterialGroup: [],
      selectedLanguage: {},
      dataLoading: true,
      error: '',
      photoModel: null,
      file: null,
      importResult: 'deneme',
      addPopupVisible: false,
      photoPop: false,
      photoPopButton: {
        text: 'Close',
        onClick: () => {
          this.photoModel = null
          this.photoPop = false;
        }
      },
    };
  },

  async mounted() {
    await this.getWbsData();
    await this.getPageAuths();
    await this.getLanguage();
    await this.getUnit();
    await this.getUsers();
    await this.getBoqData();
    await this.getCurrency();
    await this.getMaterialGroup();
    await this.getUsersName();
  },
  methods: {
    photoIconClick(e) {
      this.photoModel = e.row.data
      this.photoPop = true

    }, photoIconVisible(e) {
      return !(e.row.data.photo === undefined)

    },
    onExported: function (e) {
      e.component.columnOption("photo", "visible", false);
      e.component.endUpdate();
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Fama-Material-Report');
      e.component.beginUpdate();
      e.component.columnOption("photo", "visible", true);
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function ({gridCell, excelCell}) {
          excelCell.font = {name: 'Arial', size: 10};
          excelCell.alignment = {horizontal: 'left'};

          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'photo') {
              excelCell.value = undefined;

              if (gridCell.value != null) {
                const image = workbook.addImage({
                  base64: gridCell.value,
                  extension: 'png',
                });

                worksheet.getRow(excelCell.row).height = 90;
                worksheet.addImage(image, {
                  tl: {col: excelCell.col - 1, row: excelCell.row - 1},
                  br: {col: excelCell.col, row: excelCell.row},
                });
              }

            }
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
              new Blob([buffer], {type: 'application/octet-stream'}),
              'Fama-GeneralProblem.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'Material Report'
      });
      this.can_edit = pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = pageAuths.data.can_delete;
      this.can_create = pageAuths.data.can_create;
    },
    async getWbsData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-wbs');

      try {
        this.dataWbs = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getActivityList() {
      this.error = '';
      const response = await axios.get('/list-activity-dash');

      try {
        this.dataPopUpActivityName = response?.data?.result || [];
        activityListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    }
    ,

    isDisabledEdit(e) {
      return !(e.row.data.type === 'Material');
    },
    async getUsersName() {
      this.error = '';
      const response = await axios.get('/list-site-user');

      try {
        this.dataUsersName = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getMaterialGroup() {
      this.error = '';
      const response = await axios.get('/list-material-group');

      try {
        this.dataEditMaterialGroup = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnit() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUsers() {
      this.error = '';
      const response = await axios.get('/list-position');

      try {
        this.dataPositions = response?.data || [];

      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getMaterial() {
      this.error = '';
      const response = await axios.get('/list-material/');

      try {
        this.dataEditMaterial = response?.data?.result || [];
        materialListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getCurrency() {
      this.error = '';
      const response = await axios.get('/list-currency');

      try {
        this.dataCurrency = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },


    async getBoqData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('list-work-all-material');

      try {
        this.databoq = response?.data?.result || [];
        let posss = response?.data?.posss || []
        let mainFirms = response?.data?.mainFirms || []
        let subFirms = response?.data?.subFirms || []
        this.databoq.forEach(d => {
          d.chiefPosId = posss.find(c => c.id == d.work.submitter_id)
          d.chiefName = ""
          let wastage = d.work?.activity?.wastage || 0
          d.netQty = (d.work?.quantity || 0) * (1 - wastage)
          if (d.chiefPosId) {
            d.chiefName = d.chiefPosId.chief_position_name
          }
          if (d.work_material_images !== undefined && d.work_material_images.length > 0) {
            d.photo = d.work_material_images[0] ? 'data:image/png;base64,' + this.blobToBase64(d.work_material_images[0].image.data) : null
          }
            if (d.work.is_main_firm) {
                d.work.firm.name = mainFirms.find(a=>a.id == d.work.firm_id).name
            } else {
              console.log(subFirms);
                d.work.firm.name = subFirms.find(a=>a.id == d.work.sub_firm_id).name
            }
        })
        this.dataLoading = false;
      } catch (e) {
        this.dataLoading = false;
        this.error = e?.response?.data?.message || e?.message;
      }
    },

    async refreshList() {
      this.dataLoading = true;
      await this.getWbsData();
      await this.getLanguage();
      await this.getUnit();
      await this.getBoqData();
      await this.getCurrency();
      await this.dataProblem();
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },


    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.getBoqData();
    },

    blobToBase64(blob) {
      return btoa(
          blob.reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
    },
    onValueChanged(e) {
    }
  },

};
</script>

<style lang="scss">
@import './tables.scss';


.dx-master-detail-cell {
  background-color: bisque !important;
}
</style>

